//JS: lovellGlobalFunction.js
// Lovell Sports website functions - global (desktop / mobile)

// Set-up JavaScript skinning default values
var skinJS_SiteCode = 'LR';
var skinJS_SiteBase = 'LR';
var skinJS_SiteURL = 'www.lovell-rugby.co.uk';
var skinJS_SecureServer = 'https://www.lovell-rugby.co.uk';
var skinJS_RunningOn = 'WEB';
var skinJS_Language = 'EN';

var jslocalstrings = [];

// Holds Google Analytics data that may or may not get sent
var gaData;
analyticsInit();

// main document ready call to run general setup stuff
$(function() {
  // add language code as class to body
  $('body').addClass(skinJS_Language);

  var resizeTimer;
  $(window).on("resize", function() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      // Run code here, resizing has "stopped"
      // some overlay objects (megamenu, filter controls) on mobile will disable scrolling on the body - release this on resize
      if(getDeviceType() != 'mobile') {
        $('body').css('overflow-y', 'auto');
      }
      swapResponsiveImages('img[data-srcxs], img[data-srcsm], img[data-srcmd], img[data-srclg]');
      swapResponsiveVideo('video[data-srcxs], video[data-srcsm], video[data-srcmd], video[data-srclg]');
      swapResponsiveIframe(
        'div.vimeo-wrapper[data-srcxs][data-srcsm][data-srcmd][data-srclg]'
      );
    }, 250);
  }).resize();

  // apply selectOrDie plugin to select boxes - this hides the form select and creates a new stylable version
  if(typeof $("select").selectOrDie == 'function') {
    $("select:not(.newselect-searchable, .newselect)").selectOrDie();
  }

  var imgCheckboxOn = skinJS_SiteURL + '/responsive-images/checkbox-on.svg';
  var imgCheckboxOff = skinJS_SiteURL + '/responsive-images/checkbox-off.svg';

  // remove any existing checkbox replacements before creating new one
  if($('.checkbox-wrapper').length) {
    $('.sleeve-patch .checkbox-wrapper').remove();
  }

  // Insert checked checkbox image after every checked checkbox with a class of 'checkbox-input'
  $(".checkbox-input:checked").after('<div class="checkbox-wrapper"><img class="checkbox-image" src="' + imgCheckboxOn + '" border="0" height="39" width="39"></div>');
  // Insert unchecked checkbox image after every unchecked checkbox with a class of 'checkbox-input'
  $(".checkbox-input:not(:checked)").after('<div class="checkbox-wrapper"><img class="checkbox-image" src="' + imgCheckboxOff + '" border="0" height="39" width="39"></div>');
  // Hide all checkboxes with a class of 'checkbox-input' so that they are replaced by the image
  $(".checkbox-input").hide(0);

  // Triggered when a checkbox image has been clicked
  $('.checkbox-image').on('click', function() {
    switchCheckboxImage(this);
  });

  setUpScrollBars();

  // run checkoutCountryChange on page load to show/hide selectors for Ireland/USA on address forms
  $('.addCountry').each(function() {
    var prefix = $(this).attr('id').replace('addCountry', '');
    checkoutCountryChange(prefix);
  });
  // run checkoutCountryChange on country change to show/hide selectors for Ireland/USA on address forms
  $('.addCountry').change(function() {
    var prefix = $(this).attr('id').replace('addCountry', '');
    checkoutCountryChange(prefix);
    // reset the value in the County select when the country changes
    clearCountyValue(prefix);
  });

  // if the UK county selector is being used, update the addCounty field value to the selected Irish county when it's changed
  $('.UKCountySelector').change(function() {
    // get the prefix of the selector
    var prefix = $(this).attr('id').replace('UKCountySelector', '');
    if($(this).val() != 0) {
      $('#' + prefix + 'addCounty').val($(this).val());
    } else {
      $('#' + prefix + 'addCounty').val('');
    }
    $('#countyDiv .field-error').hide();
    removeFieldError($('#' + prefix + 'addCounty'));
  });
  // if the Irish county selector is being used, update the addCounty field value to the selected Irish county when it's changed
  $('.irishCountySelector').change(function() {
    // get the prefix of the selector
    var prefix = $(this).attr('id').replace('irishCountySelector', '');
    if($(this).val() != 0) {
      $('#' + prefix + 'addCounty').val($(this).val());
    } else {
      $('#' + prefix + 'addCounty').val('');
    }
    $('#countyDiv .field-error').hide();
    removeFieldError($('#' + prefix + 'addCounty'));
  });

  // if the USA state selector is being used, update the addCounty field value to the selected state when it's changed
  $('.USAStateSelector').change(function() {
    // get the prefix of the selector
    var prefix = $(this).attr('id').replace('USAStateSelector', '');
    if($(this).val() != 0) {
      $('#' + prefix + 'addCounty').val($(this).val());
    } else {
      $('#' + prefix + 'addCounty').val('');
    }
    $('#countyDiv .field-error').hide();
    removeFieldError($('#' + prefix + 'addCounty'));
  });

  // on smaller screens, position any error messages at the top of the page
  if(getDeviceType() == 'mobile') {
    if($('.form-error-field').length) {
      $(window).scrollTop($('.form-error-field').position().top - 10);
    } else if($('.page-scroll-target').length) {
      $(window).scrollTop($('.page-scroll-target').position().top - 10);
    }
  }

  // Check for non-Cyrillic, non-Latin characters
  $('input[type=text],input[type=tel],input[type=email]').on('change', function(e) {
    if(validateCharactersInField($(this), true)) {
      removeFieldError($(this));
    }
  });

  // clear checkbox error status
  $('input[type=checkbox]').change(function() {
    if($(this).is(':checked')) {
      removeCheckboxError($(this));
    }
  });

  $('body').on('click', function(e) {
    handleClickScroll(e);
  });

  // show fields as valid as user enters text
  $('.field-control input:not(input[name="order_is_gift_message"]):not(input[name="partnerclub"]), .field-control textarea, #countyDiv, #add_searchPostcode, .del_add_AddressInputs input').on('change keyup input propertychange', function() {
    checkFieldValid($(this));
  });

  $('.lovell-form select').on('change propertychange', function() {
    checkFieldValid($(this));
  });

  // bwx 20180312: Add our own kludge to handle maxlength on text fields, since Chrome on Android cant
  $("input[type='text'][maxlength]").on("keyup", function() {
    var maxlength = this.getAttribute("maxlength");
    if(this.value.length > maxlength) {
      this.value = this.value.substring(0, maxlength);
    }
  });

  $('.cookie-notice__accept').on('click', function() {
    $(this).closest('.cookie-notice').addClass('accepted');
  });

  $(".btn-load-on-click").on('click', function (evt) {
    startLoading(evt.currentTarget);
  });

  $('.newselect-searchable, .newselect').each(function (i, e) {
    $(e).select2({
      closeOnSelect: false,
      multiple: true,
      width: "style",
      allowClear: true,
      scrollAfterSelect:true
    });
  });

  // Disable search field on non-searchable select2 dropdowns.
  var $searchfield = $('.newselect').parent().find('.select2-search__field');
  $searchfield.prop('disabled', true);
  $searchfield.hide();

  $('.newselect, .newselect-searchable').on('change', function (event) {
    if ($(this).hasClass('newselect')) {
      var $searchfield = $(this).parent().find('.select2-search__field');
      $searchfield.prop('disabled', true);
      $searchfield.hide();
    }
    
    if ($(this).val()) {
      $(this).addClass('optionselected');
    }
    else {
      $(this).removeClass('optionselected');
    }
  });

  $('.newselect-searchable').on('select2:select', function (event) {
    var $searchfield = $(this).parent().find('.select2-search__field');
    $searchfield.val('');
  });

  // tel links - remove site url
  $('a').each(function () {
    var href = $(this).attr('href');
    if (typeof href == typeof undefined || href == false) {
      return;
    }
    if (href.indexOf('tel:') !== -1) {
      // remove everything before 'tel:'
      href = href.substring(href.indexOf("tel:"));
      $(this).attr('href', href);
    }
  });
}); // end document ready

// global functions *************************************************

function setJSLang(lang) {
  if(typeof(lang) == 'undefined') { lang = 'EN'; }
  if(!lang.match(/^(EN|FR|ES)$/i)) { lang = 'EN'; } else { lang = lang.toUpperCase(); }
  for(var localstring in jslocalisedstrings.localstrings) {
    jslocalstrings[localstring] = jslocalisedstrings.localstrings[localstring][lang];
  }
  if(lang == 'FR' && skinJS_SiteURL == 'www.lovell-rugby.co.uk') {
    skinJS_SiteURL = 'www.lovellrugby.fr';
  }
}

function skinJSVariables(skinAsSite) {
  // Skinned variables
  // Local settings - do these first to ensure we use local URL's for the skinJS_SiteURL
  // Don't set this if it's already set in the HTML
  if(!skinJS_SiteURL) {
    var windowProtocol = window.location.protocol == 'https:' ? 'https:' : 'http:';
    skinJS_SiteURL = windowProtocol + '//' + window.location.hostname;
  }

  // Set language
  setJSLang(skinJS_Language);

  skinJS_SiteCode = skinAsSite;
  skinJS_SiteBase = skinAsSite;

  // Main variables
  if(skinAsSite == 'LR') {
    if(!skinJS_SiteURL) { skinJS_SiteURL = 'http://www.lovell-rugby.co.uk'; }
  } else if(skinAsSite == 'LS') {
    if(!skinJS_SiteURL) { skinJS_SiteURL = 'http://www.lovellsoccer.co.uk'; }
  } else if(skinAsSite == 'SON') {
    if(!skinJS_SiteURL) { skinJS_SiteURL = 'http://www.sondico.com'; }
  } else if (skinAsSite == 'GUL') {
    if (!skinJS_SiteURL) {
      skinJS_SiteURL = 'https://www.gul.com'; }
  } else if (skinAsSite == 'LSP') {
    if (!skinJS_SiteURL) {
      skinJS_SiteURL = 'https://www.lovellsports.com/'; 
    }
  }
  skinJS_SecureServer = skinJS_SiteURL.replace('http:', 'https:');
}

// this line makes IOS respond to ':active' pseudo classes on touch
document.addEventListener("touchstart", function() {}, false);

function getDeviceWidth() {
  containerWidth = parseInt($(window).width());
  return containerWidth;
}

function getDeviceType() {
  // Return the type of device based purely on screen width, this matches our responsive site break points
  var screen_sm_min = 599;
  var screen_md_min = 1079;
  var screen_lg_min = 1399;

  var deviceType = '';
  if(window.matchMedia) {
    if(window.matchMedia("(max-width: " + screen_sm_min + "px)").matches) {
      deviceType = 'mobile';
    } else if(window.matchMedia("(max-width: " + screen_md_min + "px)").matches) {
      deviceType = 'tablet';
    } else if(window.matchMedia("(max-width: " + screen_lg_min + "px)").matches) {
      deviceType = 'desktop';
    } else {
      deviceType = 'wide';
    }
  } else {
    containerWidth = getDeviceWidth();
    if(containerWidth > screen_lg_min) {
      deviceType = 'wide';
    }
    if(containerWidth <= screen_lg_min) {
      deviceType = 'desktop';
    }
    if(containerWidth <= screen_md_min) {
      deviceType = 'tablet';
    }
    if(containerWidth <= screen_sm_min) {
      deviceType = 'mobile';
    }
  }
  return deviceType;
}

// handle ctrl down
var cmdIsPressed = false;
$(document).keydown(function(event) {
  if(event.which == "91" || event.which == "93") cmdIsPressed = true;
});
$(document).keyup(function() {
  cmdIsPressed = false;
});

function swapResponsiveImages(elem) {
  // swap the element's images according to window size
  $(elem).each(function() {
    var img = $(this).attr('src');
    var deviceType = getDeviceType();
    if(deviceType == 'mobile') {
      if($(this).attr('data-srcxs') !== undefined) {
        img = $(this).attr('data-srcxs');
      }
    } else if(deviceType == 'tablet') {
      if($(this).attr('data-srcsm') !== undefined) {
        img = $(this).attr('data-srcsm');
      }
    } else if(deviceType == 'desktop') {
      if($(this).attr('data-srcmd') !== undefined) {
        img = $(this).attr('data-srcmd');
      }
    } else if(deviceType == 'wide') {
      if($(this).attr('data-srclg') !== undefined) {
        img = $(this).attr('data-srclg');
      }
    }

    // bwx 20190318 owl carousels lazy loader uses data-src as the target, be sure to swap a value in there too.
    $(this)
      .attr('src', img)
      .data('src', img)
      .removeClass('hide');
  });
}

function swapResponsiveVideo(elem) {
  // swap the element's images according to window size
  var src;
  $(elem).each(function() {
    if(getDeviceType() == 'mobile') {
      if($(this).attr('data-srcxs') !== undefined) {
        if($(this).find('source').attr('src') == $(this).attr('data-srcxs')) {
          return;
        }
        src = $(this).attr('data-srcxs');
      }
    } else if(getDeviceType() == 'tablet') {
      if($(this).attr('data-srcsm') !== undefined) {
        if($(this).find('source').attr('src') == $(this).attr('data-srcsm')) {
          return;
        }
        src = $(this).attr('data-srcsm');
      }
    } else if(getDeviceType() == 'desktop') {
      if($(this).attr('data-srcmd') !== undefined) {
        if($(this).find('source').attr('src') == $(this).attr('data-srcmd')) {
          return;
        }
        src = $(this).attr('data-srcmd');
      }
    } else if(getDeviceType() == 'wide') {
      if($(this).attr('data-srclg') !== undefined) {
        if($(this).find('source').attr('src') == $(this).attr('data-srclg')) {
          return;
        }
        src = $(this).attr('data-srclg');
      }
    }
    $(this).find('source').attr('src', src);
    $(this)[0].load();
    $(this).trigger('play');
    $(this).removeClass('hide');

  });
}

function swapResponsiveIframe(elem) {
  // swap the element's images according to window size
  var src;
  $(elem).each(function () {
    if (getDeviceType() == 'mobile') {
      if ($(this).attr('data-srcxs') !== undefined) {
        src = $(this).attr('data-srcxs');
      }
    } else if (getDeviceType() == 'tablet') {
      if ($(this).attr('data-srcsm') !== undefined) {
        src = $(this).attr('data-srcsm');
      }
    } else if (getDeviceType() == 'desktop') {
      if ($(this).attr('data-srcmd') !== undefined) {
        src = $(this).attr('data-srcmd');
      }
    } else if (getDeviceType() == 'wide') {
      if ($(this).attr('data-srclg') !== undefined) {
        src = $(this).attr('data-srclg');
      }
    }
    $(this).attr('src', src);
  });
}

var sizesData = {};
var sizesList = {};

function formatDisplaySize(displaySize, useLongSizes) {
  // Format the size for display
  if(displaySize) {
    displaySize = displaySize.toString();
    displaySize = displaySize.replace(/\.5/g, '&frac12;');
    displaySize = displaySize.replace(/\.3/g, '&frac13;');
    displaySize = displaySize.replace(/\.7/g, '&frac23;');

    if(useLongSizes) {
      // Use long size names (e.g. on the mobile site)
      displaySize = displaySize.replace(/^XSB$/i, jslocalstrings['Extra Small Boys']);
      displaySize = displaySize.replace(/^SB$/i, jslocalstrings['Small Boys']);
      displaySize = displaySize.replace(/^MB$/i, jslocalstrings['Medium Boys']);
      displaySize = displaySize.replace(/^LB$/i, jslocalstrings['Large Boys']);
      displaySize = displaySize.replace(/^XLB$/i, jslocalstrings['Extra Large Boys']);

      displaySize = displaySize.replace(/^XS$/i, jslocalstrings['Extra Small']);
      displaySize = displaySize.replace(/^S$/i, jslocalstrings['Small']);
      displaySize = displaySize.replace(/^M$/i, jslocalstrings['Medium']);
      displaySize = displaySize.replace(/^L$/i, jslocalstrings['Large']);
      displaySize = displaySize.replace(/^XL$/i, jslocalstrings['Extra Large']);
      displaySize = displaySize.replace(/^XXL$/i, jslocalstrings['2X Large']);
      displaySize = displaySize.replace(/^(\d)XL$/i, '$1' + jslocalstrings['X Large']);
    }
  }

  return displaySize;
}

function makeValidId(id) {
  // Change illegal characters to legal.
  // for exact details see perl sub
  // Must do the same as the perl sub of the same name
  id = id.toString().replace(/[^a-z0-9_\-]/ig, '-');
  id = id.replace(/^([0-9]|$)/i, 'a$1');
  id = id.toLowerCase();
  return id;
}

function screenSize() {
  // Finds size and scroll position of browser window
  var docWidth = 0,
    docHeight = 0;
  if(typeof(window.innerWidth) == 'number') {
    // Non-IE
    docWidth = window.innerWidth;
    docHeight = window.innerHeight;
  } else if(document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
    // IE 6+ in 'standards compliant mode'
    docWidth = document.documentElement.clientWidth;
    docHeight = document.documentElement.clientHeight;
  } else if(document.body && (document.body.clientWidth || document.body.clientHeight)) {
    // IE 4 compatible
    docWidth = document.body.clientWidth;
    docHeight = document.body.clientHeight;
  }

  var scrOfX = 0,
    scrOfY = 0;
  if(typeof(window.pageYOffset) == 'number') {
    // Netscape compliant
    scrOfY = window.pageYOffset;
    scrOfX = window.pageXOffset;
  } else if(document.body && (document.body.scrollLeft || document.body.scrollTop)) {
    // DOM compliant
    scrOfY = document.body.scrollTop;
    scrOfX = document.body.scrollLeft;
  } else if(document.documentElement && (document.documentElement.scrollLeft || document.documentElement.scrollTop)) {
    // IE6 standards compliant mode
    scrOfY = document.documentElement.scrollTop;
    scrOfX = document.documentElement.scrollLeft;
  }
  return(docWidth + ',' + docHeight + ',' + scrOfX + ',' + scrOfY);
}

var PAFSearchTimeout;

function searchPAFAddresses(searchPostcode, houseNameOrNumber, targetPrefix, multiSelect) {
  // Clean up/check validity of postcode and call AJAX function to search PAF addresses
  // Also display some kind of 'in progress' message if we're doing a search.
  if (!fieldEmpty(targetPrefix + '_searchPostcode', jslocalstrings['Please enter your postcode'])) {
    clearTimeout(PAFSearchTimeout);
    var data = {
      postCode: searchPostcode,
      houseNameOrNumber: houseNameOrNumber,
      targetPrefix: targetPrefix,
      language: skinJS_Language,
      multiSelect: multiSelect
    };
    PAFSearchTimeout = setTimeout(doSearchPAFAddresses(data), 300);
  }
}

function loadPAFAddressResults(results) {
  // Load in paf address lookup dropdown box from AJAX. If we're on the responsive site, style it with selectboxit plugin.
  var targetDiv = results.substring(0, results.indexOf('~~'));
  results = results.substring(results.indexOf('~~') + 2, results.length);
  $('#searchResults_' + targetDiv).html(results);
  $('#searchResults_' + targetDiv + ' select').selectOrDie();
}

function doSearchPAFAddresses(data) {
  // AJAX function to lookup an address from a postcode
  $.ajax({
    type: "POST",
    url: skinJS_SiteURL + "/cgi-bin/ajax-postcodelookup.cgi",
    data: data,
    error: function (XMLHttpRequest, textStatus, errorThrown) {
      //
    },
    success: function (newData) {
      loadPAFAddressResults(newData);
    }
  });
}

function choosePAFAddress(targetPrefix, srcObject) {
  // Populate address fields with selected PAF address data
  var addressValue = srcObject.value;
  // Display address inputs. Hide 'manually enter address details' link if shown.
  $('.' + targetPrefix + '_AddressInputs').show();
  $('.' + targetPrefix + '_hideAddressInputsLink').css('visibility', 'hidden');
  var addressDetails = addressValue.split('~');
  if (addressDetails[0]) {
    $('#' + targetPrefix + 'Building').val(addressDetails[0]);
    $('#' + targetPrefix + 'Street').val(addressDetails[1]);
  } else {
    // separate the house number from the street
    var building = parseInt(addressDetails[1]);
    if (building) {
      $('#' + targetPrefix + 'Building').val(building);
      var street = addressDetails[1].replace(/[0-9]/g, '');
      $('#' + targetPrefix + 'Street').val(street);
    } else {
      $('#' + targetPrefix + 'Building').val(addressDetails[1]);
      $('#' + targetPrefix + 'Street').val('');
    }
  }
  var Postcode = addressDetails[3].toUpperCase();
  if (!Postcode.match(/ /)) {
    Postcode = Postcode.replace(/(.{3})$/, ' $1');
  }
  $('#' + targetPrefix + 'Town').val(addressDetails[2]);
  $('#' + targetPrefix + 'Postcode').val(Postcode);
  $('#' + targetPrefix + 'County').val('');
  $('#' + targetPrefix + 'Country').val('GB');

  $('.update-button').removeClass('disabled');
  if (targetPrefix == 'del_add_new_' || targetPrefix == 'del_add__') {
    $('.full-address-link').hide();
    $('.find-address').hide();
    $('.optional-house').hide();
    $('.update-button').removeClass('disabled');
    $('.postcode-search-link').show();
    $('.full-address').show();
  }
}

/*******************
 ** GOOGLE ANALYTICS
 ********************
 * As of 2017-08 we now only need to send PRODUCTIDs in hits, other product data is tied up
 * with a seperate product data file.
 ********************/

/**
 * Sets up our Analytics nonsense
 **/
function analyticsInit() {
  gaData = {
    impressions: {},
    allowedImpressions: ""
  };

  $(document).ready(function () {
    /**
    * Handles a generic click event. This is used for tracking clicks on FUDs, homepage banners etc
    **/
    $(".ga-analytics-click").on("click", function (e) {
      if (typeof gtag === "undefined") return;
      // Don't send if this is a right click
      if (e.button > 1) return;

      let $target   = $(e.currentTarget);
      let category  = $target.data("ga-event-category");
      let label     = $target.data("ga-event-label");

      gtag('event', 'click_banner', {
        bannerType: category,
        bannerName: label
      });
    });
  });
}

function analyticsNewsSubscribe(subscribeAction, source) {
// Sends a newsletter subscribe/unsubscribe event to analytics
// subscribeAction: 'subscribe' or 'unsubscribe'
  if (typeof gtag === "undefined") return;
  gtag('event', 'update_newsletter_subscription', {
    newsletter_source: source,
    action: subscribeAction, // subscribe or unsubscribe
  });
}

/**
 * Sends an add/remove from cart event to analytics
 * productData : {
 *   id (required), name, brand, price, position, variant, category
 * }
 * listType    : List type this click came from - see chooser for what we call various product list types, e.g, RELATEDITEMS, OFFERSCOUNTDOWN etc
 **/
function analyticsProductClick(productData, listType, listID) {
  if (typeof gtag === "undefined") return;
  if (!productData["item_id"]) return;

  // Don't send if this is a right click
  if (window.event.button > 1) return;

  // If this click has a listType, cookify it so add to carts from the following page can be attributed
  if (listType) {
    var date = new Date(new Date().getTime() + (2 * 60 * 1000));
    document.cookie = "gaListTypeClick=" + listType + "; expires=" + date.toString() + "; path=/; secure; samesite=lax";
  }

  gtag("event", "select_item", {
    item_list_id: listID,
    item_list_name: listType,
    items: [productData]
  });
}

/**
 * Sends an add/remove from cart event to analytics
 * productData : {
 *   id, (required)
 *   name,
 *   quantity, (required)
 *   brand,
 *   price,
 *   variant
 * }
 * type        : Either "add" or "remove"
 **/
function analyticsAddRemoveFromCart(productData, type) {
  if (typeof gtag === "undefined") return;
  if(!productData["id"] || !productData["quantity"] || !type) return;

  var eventType = (type.match(/add/i) ? "add_to_cart" : "remove_from_cart");
  var productName = productData["productName"];
  productName = productName.substring(0, 99);
  gtag("event", eventType, {
    currency: skinJS_Currency,
    value: productData["price"],
    items: [
      {
        item_id: productData["id"],
        item_variant: productData["variant"],
        quantity: productData["quantity"],
        item_name: productName
      }
    ]
  });
}

/**
 * Queues a hashkey to gaData.allowedImpressions, which will be added to the ga hit later
 **/
function analyticsAddAllowedImpressions(hashKey) {
  if(!gaData.allowedImpressions.match(new RegExp("\\b" + hashKey + "\\b"))) {
    gaData.allowedImpressions += hashKey + ",";
  }
}

/**
 * Adds impressions from gaData.impressions to analytics data
 **/
function analyticsSendImpressions() {
  if (typeof gtag === "undefined") return;
  var allowedImpressions = gaData.allowedImpressions.split(",");
  for (var i in allowedImpressions) {
    if (gaData.impressions[allowedImpressions[i]] == undefined) { continue; }
    gtag("event", "view_item_list", gaData.impressions[allowedImpressions[i]]);
  }
}
/* /Google Analytics */

function toggleShowAddressInputs(srcObject, targetDiv, justShow) {
  // Show or hide address inputs (e.g. hide whilst showing postcode selector)
  if($(targetDiv).css('display') == 'none' || justShow) {
    $(targetDiv).show();
    $(srcObject).css('visibility', 'hidden');
  }
}

function setUpScrollBars() {
  // Starts by removing any existing 'arrow scroll bars' to ensure no duplication
  $('.scroll-bar').remove();

  // Variable declarations
  var arrowImage = skinJS_SiteURL + '/responsive-images/CTA-down-arrow.svg';
  var elemId;
  var elemClasses;
  var scrollBar;
  var scrollBy;
  var scroll;
  var arrowMargin;
  var childHeight;
  var childNo;

  // Iterates over each scrollable container
  $('.scrollable').each(function(index, elem) {
    elemId = elem.id;
    // Gets height of each child of the scrollable element
    childHeight = $('#' + elemId).find('div').outerHeight();
    // Gets the number of children within scrollable element
    childNo = $('#' + elemId).children().length;
    // Seperates classes of element into an array
    elemClasses = elem.className.split(' ');
    // Itterates over each class
    elemClasses.forEach(function(elemClass) {
      if(elemClass.slice(0, 7) == 'scroll-') {
        // Get number of pixels to scroll by
        scrollBy = elemClass.split('-')[1];
        scroll = elemClass;
        // Get number of pixels between arrow icons
        arrowMargin = scrollBy - 50;
      }
    });

    // Construct scrollbar HTML
    scrollBar = '<div id="scroll-bar-' + elemId + '" class="scroll-bar ' + scroll + '">';
    scrollBar += '<img id="scroll-up-' + elemId + '" class="scroll-up" src="' + arrowImage + '" />';
    scrollBar += '<img id="scroll-down-' + elemId + '" src="' + arrowImage + '" />';
    scrollBar += '</div>';

    // If scrollable element contains enough children to need a scrollbar
    if(childHeight * childNo > scrollBy) {
      // Insert scrollbar before scrollable element
      $('.scrollable.' + scroll).before(scrollBar);
    }

    // Set up scrollbar styling
    $('#' + elemId).css({ 'overflow-y': 'hidden' });
    $('.scroll-bar').css({ width: '20px', float: 'right' });
    $('.scroll-bar img').css({ width: '20px', cursor: 'pointer' });
    $('#scroll-up-' + elemId).css({
      'margin-top': '10px',
      'margin-bottom': arrowMargin + 'px',
      'opacity': '0.2',
      '-ms-transform': 'rotate(180deg)',
      '-webkit-transform': 'rotate(180deg)',
      'transform': 'rotate(180deg)'
    });
  });
};

function handleClickScroll(e) {
  var scrollId = e.target.id.slice(0, 9);
  var divId;
  var direction;

  // If a scroll up/down button was clicked
  if(scrollId == 'scroll-up' || scrollId == 'scroll-do') {
    if(scrollId == 'scroll-up') {
      divId = e.target.id.slice(10);
      direction = 'up';
    } else if(scrollId == 'scroll-do') {
      divId = e.target.id.slice(12);
      direction = 'down';
    }
    var scrollBar = document.getElementById('scroll-bar-' + divId);
    var classes = scrollBar.className.split(' ');
    var scrollBy;
    classes.forEach(function(elemClass) {
      if(elemClass.slice(0, 7) == 'scroll-') {
        // Gets number of pixels to scroll by
        scrollBy = elemClass.split('-')[1];
      }
    });
    // Call function to perform the scroll
    verticalScroll(divId, direction, scrollBy);
  }
}

function verticalScroll(divId, direction, scrollBy) {
  var childHeight = $('#' + divId).find('div').outerHeight();

  if(direction == 'up') {
    // Gets the number of pixels scrolled so far
    var scrolled = $('#' + divId).scrollTop();
    // If number of pixels scrolled is divisible by the height of each child
    // -- This prevents the scroll animation from being called again until it has finished
    if((scrolled % childHeight) == 0) {
      // Ensure 'scroll-down' icon is not greyed out
      $('#scroll-down-' + divId).css({ opacity: 1 });
      // Perform scroll
      var scroll = $('#' + divId).animate({ scrollTop: scrolled - scrollBy }, 400);
      scroll.promise().done(function() {
        scrolled = $('#' + divId).scrollTop();
        // If scrolled to top, grey out the 'scroll-up' icon
        if(scrolled == 0) {
          $('#scroll-up-' + divId).css({ opacity: 0.2 });
        }
      });
    }
  }

  if(direction == 'down') {
    // Gets the number of pixels scrolled so far
    var scrolled = $('#' + divId).scrollTop();
    // If number of pixels scrolled is divisible by the height of each child
    // -- This prevents the scroll animation from being called again until it has finished
    if((scrolled % childHeight) == 0) {
      // Perform scroll
      var scroll = $('#' + divId).animate({ scrollTop: scrolled + parseInt(scrollBy) }, 400);
      scroll.promise().done(function() {
        var elements = $('#' + divId).children();
        scrolled = $('#' + divId).scrollTop();
        // If scrolled to bottom, grey out the 'scroll-down' icon
        if(scrolled == childHeight * (elements.length - scrollBy / childHeight)) {
          $('#scroll-down-' + divId).css({ opacity: 0.2 });
        }
      });
      // Ensure 'scroll-up' icon is not greyed out
      $('#scroll-up-' + divId).css({ opacity: 1 });
    }
  }
}

var posleft = 0;
var postop = 0;

function findPos(obj) {
  // Returns position of supplied object
  var curleft = curtop = 0;
  if(obj) {
    var rect = obj.getBoundingClientRect();
    curleft = rect.left;
    curtop = rect.top;
  }
  posleft = Math.floor(curleft);
  postop = Math.floor(curtop);
}

// Video display functions
var curlight = '';

function hideVideo(vidpid) {
  if(vidpid) {
    curlight = vidpid
  }
  document.getElementById('light' + curlight).style.display = 'none';
  document.getElementById('fade').style.display = 'none';
}

function moveVideo(vidpid) {
  // Keeps video divs in centre of screen
  if(vidpid) {
    curlight = vidpid
  }

  var vidWidth = 427;
  var vidHeight = 260;

  var screenSizeValuesString = screenSize();
  var screenSizeValues = new Array();
  screenSizeValues = screenSizeValuesString.split(',');
  var docWidth = Math.floor(screenSizeValues[0]);
  var docHeight = Math.floor(screenSizeValues[1]);
  var scrOfX = Math.floor(screenSizeValues[2]);
  var scrOfY = Math.floor(screenSizeValues[3]);

  vidX = Math.floor(docWidth / 2 - vidWidth / 2 + scrOfX);
  vidY = Math.floor(docHeight / 2 - vidHeight / 2 + scrOfY);

  if(curlight && vidX && vidY) {
    document.getElementById('light' + curlight).style.left = vidX + 'px';
    document.getElementById('light' + curlight).style.top = vidY + 'px';
  }
  document.getElementById('fade').style.top = scrOfY + 'px';
}

function fieldEmpty(fieldname, message) {
  // returns true if a field is empty and adds an error message if one is received
  var field;
  // fieldname may be a name or id
  if($('input[name=' + fieldname + ']').length) {
    field = $('input[name=' + fieldname + ']');
  } else if ($('textarea[name=' + fieldname + ']').length) {
    field = $('textarea[name=' + fieldname + ']');
  } else if($('#' + fieldname).length) {
    field = $('#' + fieldname);
  }

  if(field) {
    if(!field.val().length || field.val() == 0) {
      if(message) {
        setFieldError(field, message);
      } else {
        removeFieldError(field);
      }
      return true;
    } else {
      if(message) {
        removeFieldError(field);
      }
      return false;
    }
  }
}

function checkboxIsChecked(fieldname) {
  if($('input[name=' + fieldname + ']').is(':checked')) {
    return true;
  } else {
    return false;
  }
}

function checkDateValid(date) {
  // Lazy date validation to replace moment.js validation.
  var pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
  if (pattern.test(date)) {
    return 1;
  }
  return 0;
}

function checkFieldValid(element) {
  var isValid = false;
  if(element.attr('type') == 'email') {
    if(emailIsValid(element.val())) {
      isValid = true;
    }
  } else if(element.attr('type') == 'tel') {
    if(phoneIsValid(element.val())) {
      isValid = true;
    }
  } else if(element.attr('id') == 'addPostcode') {
    var checkAddPostcode = element.val().replace(/ +/g, '');
    if($('#addCountry').val() == 'GB' && ukPostcodeRegExp.test(checkAddPostcode)) {
      isValid = true;
    } else if($('#addCountry').val() == 'FR' && frPostcodeRegExp.test(checkAddPostcode)) {
      isValid = true;
    } else if($('#addCountry').val() == 'ES' && esPostcodeRegExp.test(checkAddPostcode)) {
      isValid = true;
    }
  } else if(element.attr('id') == 'del_addPostcode') {
    var checkAddPostcode = element.val().replace(/ +/g, '');
    if($('#del_addCountry').val() == 'GB' && ukPostcodeRegExp.test(checkAddPostcode)) {
      isValid = true;
    } else if($('#del_addCountry').val() == 'FR' && frPostcodeRegExp.test(checkAddPostcode)) {
      isValid = true;
    } else if($('#del_addCountry').val() == 'ES' && esPostcodeRegExp.test(checkAddPostcode)) {
      isValid = true;
    }
  } else if(element.attr('id') == 'add_searchPostcode') {
    var checkAddPostcode = element.val().replace(/ +/g, '');
    if(ukPostcodeRegExp.test(checkAddPostcode)) {
      isValid = true;
    }
  } else if (element.prop('nodeName') == 'SELECT') {
    if (element.hasClass("newselect-searchable") || element.hasClass("newselect")) {
      if (element.val() != 0 && element.val() != 'Please select' && element.val() != 'None') {
        isValid = true;
      }
    }
    else {
      if (element.val() != 0 && element.val().trim() != 'Please select' && element.val().trim() != 'None') {
        isValid = true;
      }
    }
    
  } else if (element.attr('id') == 'newsletter-dob') {
    if (checkDateValid($('input#newsletter-dob').val())) {
      isValid = true;
    }
  } else {
    if(element.val().trim().length > 0) {
      isValid = true;
    }
  }

  if(isValid) {
    setFieldValid(element);
    removeFieldError(element);
  } else {
    removeFieldValid(element);
  }
  return isValid;
}

function setFieldValid(element) {
  if(element.prop('nodeName') == 'SELECT') {
    element.closest('.sod_select').addClass('is-valid');
  } else {
    element.closest('.field-control').addClass('is-valid');
  }
}

function removeFieldValid(element) {
  if(element.prop('nodeName') == 'SELECT') {
    element.closest('.sod_select').removeClass('is-valid');
  } else {
    element.closest('.field-control').removeClass('is-valid');
  }
}

function setFieldError(element, message) {
  var borderElement = element;
  // clear any previous errors
  removeFieldError(element);
  if(element.attr('id') == 'addCounty' || element.attr('id') == 'del_addCounty') {
    // if the empty field is county, it may be hidden and a county select displayed istead (UK, IE, USA)
    // if so, set the error to the select
    if(!element.is(':visible')) {
      message = '';
      element = element.closest('.field-county').find('.sod_select:visible select');
    }
  }

  if(element.parent().hasClass('sod_select')) {
    element = element.parent();
    borderElement = element.find('.sod_label');
  }
  if(element) {
    if(!element.siblings('.field-error').length) {
      element.before('<span class="field-error">' + message + '</span>');
    }
    if (borderElement) {
      borderElement.closest('.field').addClass('has-error').focus();
    }
  }
}

function setCheckboxError(element, message) {
  // clear any previous errors
  removeCheckboxError(element);
  if (message.length) {
    element.closest('label').append('<span class="field-error">' + message + '</span>');
  }
  element.closest('label').addClass('error');
}

function removeFieldError(element) {
  if(element.attr('id') == 'addCounty' || element.attr('id') == 'del_addCounty') {

    // if the empty field is county, it may be hidden and a county select displayed istead (UK, IE, USA)
    // if so, remove the error from the select
    if(!element.is(':visible')) {
      element = element.closest('.field-county').find('.sod_select:visible select');
    }
  }
  var borderElement = element;
  if(element.parent().hasClass('sod_select')) {
    element = element.parent();
    borderElement = element.find('.sod_label');
  }
  if(element) {
    element.siblings('.field-error').remove();
    borderElement.closest('.field').removeClass('has-error');
  }
}

function removeCheckboxError(element) {
  // clear any previous errors
  if(element.closest('label').length) {
    element.closest('label').removeClass('error');
    element.closest('label').find('.field-error').remove();
  }
}

var ukPostcodeRegExp = /^(BFPO\d+|[a-z]{1,2}\d{1,2}[a-z]?\d[a-z]{2})$/i;
var frPostcodeRegExp = /^\d{5}$/;
var esPostcodeRegExp = /^\d{5}$/;

function createCookie(name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = "; expires=" + date.toGMTString();
  } else var expires = "";

  document.cookie = name + "=" + value + expires + "; path=/; secure; samesite=lax";
}

function deleteCookie(name) {
  createCookie(name, "", "-1");
}

function getCookie(getCookieName) {
  var allCookies = document.cookie.split(';');
  for(var i = 0; i < allCookies.length; i++) {
    var cookiename;
    var cookievalue;
    cookiename = allCookies[i].substr(0, allCookies[i].indexOf("="));
    cookievalue = allCookies[i].substr(allCookies[i].indexOf("=") + 1);
    cookiename = cookiename.replace(/^\s+|\s+$/g, '');
    cookievalue = cookievalue.replace(/^\s+|\s+$/g, '');
    if(cookiename == getCookieName) {
      return cookievalue;
      break;
    }
  }
}

function emailIsValid(field) {
  // Returns true if format of input is a valid e-mail address
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(field);
}

function phoneIsValid(field) {
  var regex = /^[0-9\+\-\(\)\s]+$/;
  var isValid = regex.test(field);
  if(isValid && field.length > 6) {
    return true;
  } else {
    return false;
  }
}

function validCharacters(formOk) {
  // checks for and disallows, e.g., Chinese and Farsi script in all fields
  $('input[type=text],input[type=tel],input[type=email]').each(function() {
    formOk = validateCharactersInField($(this), formOk);
  });
  return formOk;
}

function validateCharactersInField(thisField, formOk) {
  // checks for and disallows, e.g., Chinese and Farsi script in all fields
  var includedCharacters = /^[\x00-\xFF]+$/i;
  var stringToTest = thisField.val();
  if(stringToTest && !includedCharacters.test(stringToTest)) {
    setFieldError(thisField, jslocalstrings['Non-ASCII characters detected']);
    formOk = false;
  }
  return formOk;
}

function isScrolledIntoView(elem) {
  var $elem = $(elem);
  var $window = $(window);

  var docViewTop = $window.scrollTop();
  var docViewBottom = docViewTop + $window.height();

  var elemTop = $elem.offset().top + 100;
  var elemBottom = elemTop + $elem.height() + 100;

  return((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

// Core AJAX code

function getVal(id) {
  if(id.constructor == Function) { return id() }
  if(typeof(id) != 'string') { return id }
  var element = document.getElementById(id);
  if(!element) {
    for(var i = 0; i < document.forms.length; i++) {
      element = document.forms[i].elements[id];
      if(element) break;
    }
    if(element && !element.type) element = element[0];
  }
  if(!element) {
    alert('ERROR: Cannot find HTML element with id or name: ' + id + '. ' + 'Check that an element with name or id=' + id + ' exists');
    return 0;
  }
  if(element.type == 'select-one') {
    if(element.selectedIndex == -1) { return }
    var item = element[element.selectedIndex];
    return item.value || item.text;
  }
  if(element.type == 'select-multiple') {
    var ans = [];
    var k = 0;
    for(var i = 0; i < element.length; i++) {
      if(element[i].selected || element[i].checked) {
        ans[k++] = element[i].value || element[i].text;
      }
    }
    return ans;
  }
  if(element.type == 'radio' || element.type == 'checkbox') {
    var ans = [];
    var elms = document.getElementsByTagName('input');
    var endk = elms.length;
    var i = 0;
    for(var k = 0; k < endk; k++) {
      if(elms[k].type == element.type && elms[k].checked && (elms[k].id == id || elms[k].name == id)) { ans[i++] = elms[k].value }
    }
    return ans;
  }
  if(element.value == undefined) {
    return element.innerHTML;
  } else {
    return element.value;
  }
}

// Generic ajax action
function doAjaxAction(data, callback, errorCallback) {
  $.ajax({
    type: "POST",
    url: skinJS_SiteURL + "/cgi-bin/ajax-doajaxaction.cgi",
    data: data,
    error: function(XMLHttpRequest, textStatus, errorThrown) {
      if(errorCallback) errorCallback(XMLHttpRequest, textStatus, errorThrown);
    },
    success: function(newData) {
      if(callback) callback(newData);
    }
  });
}

function replaceNbsps(str) {
  // Replace non-breaking spaces with regular spaces
  var re = new RegExp(String.fromCharCode(160), "g");
  return str.replace(re, " ");
}

function stripAccentedCharacters(accentedString) {
  // Strip accents from javascript string
  var r = accentedString;
  if(r.length > 0) {
    r = r.replace(new RegExp("[àáâãäå]", 'g'), 'a');
    r = r.replace(new RegExp("[ÁÀ]", 'g'), 'A');
    r = r.replace(new RegExp("æ", 'g'), 'ae');
    r = r.replace(new RegExp("ç", 'g'), 'c');
    r = r.replace(new RegExp("[èéêë]", 'g'), 'e');
    r = r.replace(new RegExp("[ÉÈ]", 'g'), 'E');
    r = r.replace(new RegExp("[ìíîï]", 'g'), 'i');
    r = r.replace(new RegExp("ñ", 'g'), 'n');
    r = r.replace(new RegExp("[òóôõö]", 'g'), 'o');
    r = r.replace(new RegExp("[ùúûü]", 'g'), 'u');
    r = r.replace(new RegExp("[ýÿ]", 'g'), 'y');
  }
  return r;
}

function startLoading($el){
  $($el).each(function() {
    var $this = $(this);
    $this
      .data({ previousText: $this.html() })
      .addClass("btn-loading")
      .html("loading..." + '<span class="loader"></span>');
  });
}

function stopLoading($el, text) {
  $($el).each(function () {
    var $this = $(this);
    $this
      .removeClass("btn-loading")
      .html(text || $this.data('previousText'))
      .data({ previousText: '' });

    if ($this.hasClass("btn-disable-on-click"))
      $this.prop('disabled', false);
  });
}
function stopAllLoading() {
  stopLoading($(".btn-loading"));
}

function warrantyRegistration() {
  var formOk = true;

  var name = document.getElementById('name').value;
  if(fieldEmpty('name', 'Please enter your name')) formOk = false;

  var email = document.getElementById('email').value;
  if(fieldEmpty('email', 'Please enter your email address')) {
    formOk = false;
  } else {
    if (!emailIsValid(email)) {
      setFieldError($('#email'), 'Not a valid email address');
      formOk = false;
    }
  }

  var purchasedFrom = document.getElementById('purchasedFrom').value;
  if(fieldEmpty('purchasedFrom', 'Please tell us where you purchased the product')) formOk = false;

  var description = document.getElementById('description').value;
  if(fieldEmpty('description', 'Please enter the product name, size and colour')) {
    formOk = false;
  }

  var sku = document.getElementById('sku').value;
  var price = document.getElementById('price').value;
  var purchasedDate = document.getElementById('purchasedDate').value;

  if (formOk) {
    var data = {
      action:         'WARRANTY_FORM',
      name:           name,
      email:          email,
      sku:            sku,
      purchasedDate:  purchasedDate,
      purchasedFrom:  purchasedFrom,
      price:          price,
      description:    description
    }
    doAjaxAction(data, function(output) {
      $('#warrantyFormFeedback').html(output);
    });
  }
}

function loadWishlist() {
  doAjaxAction(
    {
      action: "LOAD_WISHLIST",
      localFavourites: localStorage.getItem('favourites'),
    }, function (output) {
      $('#wishlist-block').html(output);
    }
  );
}

function updateWishlist(e, productID, quantity, customerID, $el, remove) {
  e.preventDefault();
  if (!quantity || quantity < 1) {
    quantity = 1;
  }
  var localFavourites = localStorage.getItem('favourites') || '';
  var addOrRemove;
  if (remove) {
    localFavourites = localFavourites.replace(new RegExp(productID + '[^,]+', 'g'), '');
    localFavourites = localFavourites.replace(new RegExp(',+', 'g'), ',');
    localFavourites = localFavourites.replace(new RegExp('^,|,$', 'g'), '');
    addOrRemove = 'REMOVE';
    localStorage.setItem('favourites', localFavourites);
    $el.removeClass('active');
  }
  else if (productID.match(/^[0-9]+$/) && quantity.match(/^[0-9]+$/)) {
    localFavourites += ',' + productID + '~' + quantity;
    localFavourites = localFavourites.replace(new RegExp('^,|,$', 'g'), '');
    localStorage.setItem('favourites', localFavourites);
    addOrRemove = 'ADD';
    $el.addClass('active');
  }

  if (customerID) {
    doAjaxAction(
      {
        action: "UPDATE_WISHLIST",
        wishlistUpdates: productID + '~' + quantity,
        addOrRemove: addOrRemove
      }, function (output) {
        if (!output || output.match(/^\d+/i)) {
          localStorage.setItem('favourites', output);
        }
      }
    );
  }
}

function syncWishlistWithLocalStorage() {
  // Add favourites in local storage to wishlist and then clear local favourites.
  var favourites = localStorage.getItem('favourites');
  doAjaxAction(
    {
      action: "UPDATE_WISHLIST",
      wishlistUpdates: favourites,
      addOrRemove: 'ADD'
    }, function (output) {
      if (!output || output.match(/^\d+/i)) {
        localStorage.setItem('favourites', output);
      }
    }
  );
}

function updateCustomerPreferences(e) {
  e.preventDefault();

  $('#preferences-form-error').hide();
  var $form = $(e.target);
  var data = {
    action: 'UPDATE_PREFERENCES'
  };

  var formOk = false;
  // if the user has entered a dob, validate it
  if ($('input#newsletter-dob').length) {
    dob = $('input#newsletter-dob').val();
    if (dob.length) {
      removeFieldError($('input#newsletter-dob'));
      formOk = true;
      if (!checkDateValid(dob)) {
        setFieldError($('input#newsletter-dob'), 'Please enter a valid date of birth (DD/MM/YYYY)');
        formOk = false;
      }
    }
    else {
      setFieldError($('input#newsletter-dob'), 'Please enter a valid date of birth (DD/MM/YYYY)');
      formOk = false;
    }
  }

  if ($('input#newsletter-email').length) {
    var email = $('input#newsletter-email').val();
    if (email.length) {
      removeFieldError($('input#newsletter-email'));
      formOk = true;
      if (!emailIsValid(email)) {
        setFieldError($('input#newsletter-email'), jslocalstrings['Please enter a valid email address']);
        formOk = false;
      }
    }
    else {
      setFieldError($('input#newsletter-email'), 'Please enter a valid email address');
      formOk = false;
    }
  }

  if ($('input#newsletter-firstname').length) {
    var firstname = $('input#newsletter-firstname').val();
    if (firstname.length) {
      removeFieldError($('input#newsletter-firstname'));
      formOk = true;
    }
    else {
      setFieldError($('input#newsletter-firstname'), 'Please enter your first name.');
      formOk = false;
    }
  }

  if ($('input#newsletter-surname').length) {
    var surname = $('input#newsletter-surname').val();
    if (surname.length) {
      removeFieldError($('input#newsletter-surname'));
      formOk = true;
    }
    else {
      setFieldError($('input#newsletter-surname'), 'Please enter your surname.');
      formOk = false;
    }
  }

  $form.find('input').each(function () {
    data[$(this).attr('name')] = $(this).val();
  });
  $form.find('select').each(function () {
    data[$(this).attr('name')] = $(this).val();
  });

  if (formOk) {
    doAjaxAction(data, function (output) {
      if (output == 'success') {
        $('#preferences-form').hide();
        $('#preferences-form-error').hide();
        $('#preferences-form-success').show();
      }
      else {
        $('#preferences-form-error').html(output);
        $('#preferences-form-error').show();
      }
    });
  }
}

// Lazy load images
document.addEventListener('DOMContentLoaded', initLazyLoad);

function initLazyLoad () {
  var lazyloadImages;

  if ('IntersectionObserver' in window) {
    lazyloadImages = document.querySelectorAll('.lazy');
    var imageObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          var image = entry.target;
          if (image.dataset.src) {
            image.src = image.dataset.src;
          }
          if (image.dataset.srcset) {
            image.srcset = image.dataset.srcset;
          }
          image.classList.remove('lazy');
          var spinner = image.parentNode.querySelector('.loader, .loader-dark');
          if (isElement(spinner)) {
            image.parentNode.removeChild(spinner);
          }
          imageObserver.unobserve(image);
        }
      });
    });

    lazyloadImages.forEach(function (image) {
      imageObserver.observe(image);
    });
  } else {
    var lazyloadThrottleTimeout;
    lazyloadImages = document.querySelectorAll('.lazy');

    function lazyload() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(function () {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function (img) {
          if (img.offsetTop < window.innerHeight + scrollTop) {
            if (img.dataset.src) {
              img.src = img.dataset.src;
            }
            if (img.dataset.srcset) {
              img.srcset = img.dataset.srcset;
            }
            img.classList.remove('lazy');
          }
        });
        if (lazyloadImages.length == 0) {
          document.removeEventListener('scroll', lazyload);
          window.removeEventListener('resize', lazyload);
          window.removeEventListener('orientationChange', lazyload);
        }
      }, 20);
    }

    document.addEventListener('scroll', lazyload);
    window.addEventListener('resize', lazyload);
    window.addEventListener('orientationChange', lazyload);
  }
}

function isElement(obj) {
  try {
    //Using W3 DOM2 (works for FF, Opera and Chrome)
    return obj instanceof HTMLElement;
  } catch (e) {
    //Browsers not supporting W3 DOM2 don't have HTMLElement and
    //an exception is thrown and we end up here. Testing some
    //properties that all elements have (works on IE7)
    return (
      typeof obj === 'object' &&
      obj.nodeType === 1 &&
      typeof obj.style === 'object' &&
      typeof obj.ownerDocument === 'object'
    );
  }
}

// Clamp a number between min and max
function clamp(val, min, max) {
  min = min || Number.MIN_SAFE_INTEGER;
  max = max || Number.MAX_SAFE_INTEGER;
  return (val > max ? max : (val < min ? min : val));
}

// truncate any text blocks with class '.truncate'
$(function () {
  $('.truncate').each(function (i, e) {
    if (e.offsetHeight < e.scrollHeight) {
      // show read more button
      $('<div class="readmore truncate-link">Read More</div>').insertAfter(e);
    }
  });

  $(document).on('click', '.readmore', function () {
    $(this).prev('.truncate').css('display', 'block');
    $(this).removeClass('readmore').addClass('readless').text('Read Less');
  });

  $(document).on('click', '.readless', function () {
    $(this).prev('.truncate').css('display', '-webkit-box');
    $(this).removeClass('readless').addClass('readmore').text('Read More');
  });
  
});


var maxScrollProgress = 0;
// add body class if header is locked to top
document.addEventListener('DOMContentLoaded', function () {
  // Get the navbar element
  var navbar = document.querySelector('.navbar-header');

  // Set up a scroll event listener
  window.addEventListener('scroll', function () {
    // Get the bounding rect of the navbar
    if (navbar.length) {
      var navbarRect = navbar.getBoundingClientRect();
      // Check if the top of the navbar is at or above the top of the viewport
      if (navbarRect.top <= 0) {
        // If at the top, add the 'fixed-header' class to the body
        document.body.classList.add('navbar-fixed');
      } else {
        // If not at the top, remove the 'fixed-header' class from the body
        document.body.classList.remove('navbar-fixed');
      }
    }
    if (typeof gtag !== "undefined") {
      var scrollProgress = ($(window).scrollTop() / ($(document).height() - $(window).height())) * 100;
      if (scrollProgress > maxScrollProgress) {
        var sendScrollProgress = 0;
        if (scrollProgress > 25 && maxScrollProgress < 25) {
          sendScrollProgress = 25;
        }
        else if (scrollProgress > 50 && maxScrollProgress < 50) {
          sendScrollProgress = 50;
        }
        else if (scrollProgress > 75 && maxScrollProgress < 75) {
          sendScrollProgress = 75;
        }
        maxScrollProgress = scrollProgress;
        if (sendScrollProgress) {
          gtag('event', 'scroll', {
            percent_scrolled: sendScrollProgress
          });
        }
      }
    }
  });
});


// Product page functions which are called in lots of places
function showAnimatedSpinner(element, darkVersion) {
  // prepends the spinner code into the argument element
  var loaderClass = 'loader';
  if (darkVersion) {
    loaderClass = ' loader-dark';
  }
  var html = '<div class="' + loaderClass + '">Loading...</div>';
  $(element).css('position', 'relative').prepend(html);
}

function disableImageZoom() {
  $('#img-zoomed').remove();
  $('.ZoomContainer').remove();
  $('.main-images-wrapper img').removeData('ez-plus');
  $('.magnifier').removeClass('active');
}

function textIsAllowed(str) {
  var textIsAllowed = true;
  for (var i = 0, len = str.length; i < len; i++) {
    if (
      (str.charCodeAt(i) == 67 &&
        str.charCodeAt(i + 1) == 85 &&
        str.charCodeAt(i + 2) == 78 &&
        str.charCodeAt(i + 3) == 84 &&
        str !== 'SCUNTHORPE') ||
      (str.charCodeAt(i) == 70 &&
        str.charCodeAt(i + 1) == 85 &&
        str.charCodeAt(i + 2) == 67 &&
        str.charCodeAt(i + 3) == 75) ||
      (str.charCodeAt(i) == 78 &&
        str.charCodeAt(i + 1) == 73 &&
        str.charCodeAt(i + 2) == 71 &&
        str.charCodeAt(i + 3) == 71 &&
        str.charCodeAt(i + 4) == 69 &&
        str.charCodeAt(i + 5) == 82) ||
      (str.charCodeAt(i) == 78 &&
        str.charCodeAt(i + 1) == 73 &&
        str.charCodeAt(i + 2) == 71 &&
        str.charCodeAt(i + 3) == 71 &&
        str.charCodeAt(i + 4) == 65)
    ) {
      textIsAllowed = false;
    }
  }
  if (!textIsAllowed) {
    alert('Sorry, that text is not allowed.  Please try something else.');
  }
  return textIsAllowed;
}

// Get source for checkbox images
function switchCheckboxImage(e) {
  var $e = $(e);
  var $nativeInput = $e.parent().prev('.checkbox-input');

  if ($nativeInput.prop('disabled')) return;

  var targetCheckState = !$nativeInput.prop('checked');
  var targetImageName = targetCheckState ? 'on' : 'off';
  var targetSrc = $e
    .attr('src')
    .replace(/-(on|off)\.svg/, `-${targetImageName}.svg`);
  var randQueryString = '?q=' + Math.random(); // Add a random query string to end of image src to sort browser caching issue

  // Check/uncheck the hidden checkbox and replace image accordingly
  $e.attr('src', targetSrc + randQueryString);
  $nativeInput.prop('checked', targetCheckState);
}


// Load the cookie consent
function loadCookieConsent() {
  // Once the library is loaded, initialize the cookie consent banner

  var cookieconsent = initCookieConsent();
  var cookieConsentLinkText = {
    LR: "Lovell Rugby Cookie Policy",
    LS: "Lovell Soccer Cookie Policy",
    LSP: "Lovell Sports Cookie Policy",
    SON: "Sondico Cookie Policy",
    GUL: "Gul Watersports Cookie Policy"
  };

  var cookieConsentConfig = {
    autorun: true,
    current_lang: 'en',
    autoclear_cookies: true,
    page_scripts: true,

    // onFirstAction: function (user_preferences, cookie) { },

    onAccept: function (cookie) {
      var analyticsConsent = 'denied';
      var advertisingConsent = 'denied';
      if (cookie.categories !== "undefined") {
        for (var category in cookie.categories) {
          if (cookie.categories[category] == 'analytics') {
            analyticsConsent = 'granted';
          }
          else if (cookie.categories[category] == 'advertising') {
            advertisingConsent = 'granted';
          }
        }
      }
      
      if (typeof gtag !== 'undefined') {
        gtag('consent', 'update', {
          'ad_storage': advertisingConsent, // Enables storage (such as cookies) related to advertising.
          'ad_user_data': advertisingConsent, // Sets consent for sending user data related to advertising to Google.
          'ad_personalization': advertisingConsent, // Sets consent for personalized advertising.
          'analytics_storage': analyticsConsent // Enables storage (such as cookies) related to analytics e.g. visit duration.
        });
      }
    },

    // onChange: function (cookie, changed_preferences) { },
    languages: {
      en: {
        consent_modal: {
          title: 'Our cookies',
          description:
            'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only upon approval. <a aria-label="' + cookieConsentLinkText[skinJS_SiteCode] + '" class="cc-link" href="/cookies#how-we-use">' + cookieConsentLinkText[skinJS_SiteCode] + '</a>',
          primary_btn: {
            text: 'Accept',
            role: 'accept_all', // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'Settings',
            role: 'settings', // 'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: 'Cookie preferences',
          save_settings_btn: 'Save settings',
          accept_all_btn: 'Accept all',
          reject_all_btn: 'Reject all', // optional, [v.2.5.0 +]
          cookie_table_headers: [
            { col1: 'Name' },
            { col2: 'Domain' },
            { col3: 'Expiration' },
            { col4: 'Description' },
          ],
          blocks: [
            {
              title: 'Cookie usage',
              description:
                'We use cookies, which are small text files, to improve your experience on our website and to show you personalised content. You can allow all or manage them individually.',
            },
            {
              title: 'Strictly necessary cookies',
              description:
                'These cookies are essential for the proper functioning of this website. Without these cookies, the website would not work properly.',
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true,
              },
              cookie_table: [
                {
                  col1: 'ID',
                  col2: skinJS_SiteURL,
                  col3: '1 Day',
                  col4: 'Used to uniquely identify a visitor to keep track of information for the session such as basket contents.',
                },
                {
                  col1: 'CID',
                  col2: skinJS_SiteURL,
                  col3: '1 Year',
                  col4: 'Used to uniquely identify a customer.',
                },
                {
                  col1: 'NEWSLETTERPOPUP',
                  col2: skinJS_SiteURL,
                  col3: '30 days',
                  col4: 'Sets a flag noting that the site newsletter offer popup has been displayed',
                },
                {
                  col1: 'CONSENT',
                  col2: 'google.com',
                  col3: '2 Years',
                  col4: 'Used to store a user’s state regarding their cookies choices.',
                },
                {
                  col1: 'cc_cookie',
                  col2: skinJS_SiteURL,
                  col3: '6 Months',
                  col4: 'Used to store a user’s cookie choices.',
                },
                {
                  col1: 'rmStoreGateway',
                  col2: skinJS_SiteURL,
                  col3: '30 Days',
                  col4: 'Used to store information about affilates.',
                },
                {
                  col1: 'referrer',
                  col2: skinJS_SiteURL,
                  col3: '30 Days',
                  col4: "Used to store information about the referrer to the user's website visit.",
                },
                {
                  col1: '__stripe_mid',
                  col2: skinJS_SiteURL,
                  col3: '1 Year',
                  col4: 'Used to store identifier regarding Stripe payment after an order.',
                },
                {
                  col1: '__stripe_sid',
                  col2: skinJS_SiteURL,
                  col3: 'Session',
                  col4: 'Used to store session identifier regarding Stripe payment after an order.',
                },
              ],
            },
            {
              title: 'Analytics cookies',
              description:
                'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.',
              toggle: {
                value: 'analytics',
                enabled: false,
                readonly: false,
              },
              cookie_table: [
                {
                  col1: '_ga',
                  col2: 'google.com',
                  col3: '2 years',
                  col4: 'Records a particular ID used to come up with data about website usage by the user',
                },
                {
                  col1: '_gid',
                  col2: 'google.com',
                  col3: 'Session',
                  col4: 'Keeps an entry of unique ID which is then used to come up with statistical data on website usage by visitors. It is a HTTP cookie type and expires after a browsing session.',
                },
                {
                  col1: 'gaListTypeClick',
                  col2: 'google.com',
                  col3: 'Session',
                  col4: 'Records that the user has clicked on a tracked product listing.',
                },

                {
                  col1: 'OTZ',
                  col2: 'google.com',
                  col3: '30 Days',
                  col4: 'Used by Google Analytics that provides an aggregate analysis of Website visitors. The “S” cookie may collect certain information used to help improve services, including the pages users visit most often and whether users get error messages from certain pages.',
                },
                {
                  col1: 'SOCS',
                  col2: 'google.com',
                  col3: '13 Months',
                  col4: "Used to store a user's state regarding their cookies choices.",
                },
              ],
            },
            {
              title: 'Functional cookies',
              description:
                'While not strictly necessary for the operation of the services, functional cookies are used to help improve the user experience, optimize services, and collect diagnostic information. If you disable these cookies, we will not be able to learn how you utilize our services, and our ability to improve the content and operation of our services will be impaired.',
              toggle: {
                value: 'functional',
                enabled: false,
                readonly: false,
              },
              cookie_table: [
                {
                  col1: '__Secure-ENID',
                  col2: 'google.com',
                  col3: '13 Months',
                  col4: "This cookie is used to remember your preferences and other information, such as your preferred language, how many results you prefer to display on a search results page (for example, 10 or 20) and whether you want Google's SafeSearch filter to be activated.",
                },
                {
                  col1: 'AEC',
                  col2: 'google.com',
                  col3: '6 Months',
                  col4: "AEC cookies ensure that requests within a browsing session are made by the user, and not by other sites. These cookies prevent malicious sites from acting on behalf of a user without that user's knowledge.",
                },
                {
                  col1: 'BVBRANDSID',
                  col2: 'bazaarvoice.com',
                  col3: 'Session',
                  col4: 'Allows internal Bazaarvoice web analytics to be correlated to the same user browsing session for interactions within a particular client domain.',
                },
                {
                  col1: 'BVImplmain_site',
                  col2: 'bazaarvoice.com',
                  col3: '2 Hours',
                  col4: 'Used for client-driven A/B tests.',
                },
                {
                  col1: 'joe-chnlcustid',
                  col2: 'cdn.freshbots.ai',
                  col3: '1 Year',
                  col4: 'Used to enable you to interact with the chatbot hosted by Freshdesk, remembering you by storing a unique ID.',
                },
                {
                  col1: 'spd-custhash',
                  col2: 'cdn.freshbots.ai',
                  col3: '1 Year',
                  col4: 'Used to enable you to interact with the chatbot by Freshdesk, remembering you by storing a unique ID.',
                },
              ],
            },
            {
              title: 'Advertising cookies',
              description:
                "Advertising cookies are specifically designed to gather information from you on your device to display advertisements to you based on relevant topics that interest you. Advertisers will place these cookies on a website with the website operator's permission. The information the cookies gather on you can be shared with other advertisers to measure the performance of their advertisements. Additionally, another purpose of targeting and advertising cookies is to build user profiles from visitors to the website to gather statistics on the performance of the advertisements that can carry across many websites.",
              toggle: {
                value: 'advertising',
                enabled: false,
                readonly: false,
              },
              cookie_table: [
                {
                  col1: '_ttp',
                  col2: 'tiktok.com',
                  col3: '13 Months',
                  col4: "To measure and improve the performance of your advertising campaigns and to personalize the user's experience (including ads) on TikTok",
                },
                {
                  col1: '_fbp',
                  col2: 'facebook.com',
                  col3: 'Session',
                  col4: 'Facebook tracking pixel used to identify visitors for personalized advertising.',
                },
                {
                  col1: 'scarab.visitor',
                  col2: 'emarsys.com',
                  col3: '1 Year',
                  col4: 'This cookie stores the visitor id which will identify the visitor through sessions.',
                },
                {
                  col1: '_tt_enable_cookie',
                  col2: 'tiktok.com',
                  col3: '13 Months',
                  col4: 'This cookie is used as a means of tracking your website activity to help us optimise our advertising.',
                },
                {
                  col1: 'tta_attr_id',
                  col2: 'tiktok.com',
                  col3: '364 Days',
                  col4: 'Used by TikTok to deliver a series of advertisement products such as real time bidding from 3rd party advertisers.',
                },
                {
                  col1: 'tpc_id',
                  col2: 'attentivemobile.com',
                  col3: '1 year, 1 month',
                  col4: 'Used by Attentive to deliver a series of advertisement products.',
                },
                {
                  col1: '__attentive_vf',
                  col2: 'attentive.com',
                  col3: '1 day',
                  col4: 'Used by Attentive to deliver advertisement products.',
                },
                {
                  col1: 'tta_attr_id_mirror',
                  col2: 'tiktok.com',
                  col3: '1 year',
                  col4: 'To measure and improve the performance of advertising campaigns and to personalize the user’s experience (including ads) on TikTok.',
                },
                {
                  col1: '__attentive_ss_referrer',
                  col2: 'attentive.com',
                  col3: '30 Minutes',
                  col4: 'Used to track where the user came from; primarily used for sign-up unit display logic',
                },
                {
                  col1: '_attn_',
                  col2: 'attentive.com',
                  col3: '400 days',
                  col4: 'This is a secure persistent cookie that contains the visitor ID and some page view and session details.',
                },
                {
                  col1: '__attentive_id',
                  col2: 'attentive.com',
                  col3: '60 years or, if GDPR compliant, 390 days',
                  col4: 'This is a unique visitor identifier that gets tied to a subscriber in Attentive. It’s used to attribute onsite events to the visitor and to govern display rules for Attentive sign-up units. This cookie doesn’t expire unless the shopper clears their browser cache/cookies.',
                },
                {
                  col1: '__attentive_dv',
                  col2: 'attentive.com',
                  col3: 'Depending on CCPA logic, two hours or 24 hours',
                  col4: 'Used for CCPA compliance',
                },
                {
                  col1: '__attentive_pv',
                  col2: 'attentive.com',
                  col3: '30 Minutes',
                  col4: "Used to track the number of page views in a visitor's session; primarily used for sign-up unit display logic",
                },
                {
                  col1: '__attentive_cco',
                  col2: 'attentive.com',
                  col3: '60 years or, if GDPR compliant, 390 days',
                  col4: 'Used to calculate expiration',
                },
                {
                  col1: 'attntv_mstore_email',
                  col2: 'attentive.com',
                  col3: '30 Minutes',
                  col4: 'Used at checkout for attribution',
                },
                {
                  col1: 'attntv_mstore_phone',
                  col2: 'attentive.com',
                  col3: '30 Minutes',
                  col4: 'Used at checkout for attribution',
                },
                {
                  col1: 's',
                  col2: 'facebook.com',
                  col3: 'Session',
                  col4: 'Facebook browser identification, authentication, marketing, and other Facebook-specific function cookies.',
                },
                {
                  col1: 'DV',
                  col2: 'google.com',
                  col3: '1 day',
                  col4: 'To provide ad delivery or retargeting.',
                },
              ],
            },
          ],
        },
      },
    },
    gui_options: {
      consent_modal: {
        layout: 'cloud', // box/cloud/bar
        position: 'bottom center', // bottom/middle/top + left/right/center
        transition: 'slide', // zoom/slide
        swap_buttons: false, // enable to invert buttons
      },
      settings_modal: {
        layout: 'box', // box/bar
        position: 'left', // left/right
        transition: 'slide', // zoom/slide
      },
    },
  };
  cookieconsent.run(cookieConsentConfig);
}
